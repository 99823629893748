import React from 'react';
import { useFieldArray, Controller, Control, FieldErrors } from 'react-hook-form';
import { Col, Row, Input, Button, Space, Card } from 'antd';
import { FieldErrorMessage } from './FieldErrorMessage';
import Icon, { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import TextEditorInput from './TextEditorInput';

type TabsCreatorFieldProps = {
    control: Control,
    fieldName: string,
    errors: FieldErrors<any>

};
const TabsCreatorField = ({ control, fieldName, errors }: TabsCreatorFieldProps) => {
    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
        {
            control,
            name: fieldName
        }
    );


    return (
        <Card bodyStyle={{ padding: 15 }} title="Extra Info Tabs" >
            <Space style={{ width: '100%' }} direction="vertical">
                {fields.map((item, index) => {
                    return (
                        <Row key={index} style={{ width: "100%" }} gutter={12}>
                            <Col span={24}>
                                <Input.Group size="default">
                                    <Row gutter={[5, 10]}>
                                        <Col span={12}>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}

                                                as={<Input placeholder="Title" id={`${fieldName}[${index}].title`} />}
                                                name={`${fieldName}[${index}].title`}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Controller
                                                control={control}
                                                rules={{ required: true }}
                                                as={<TextEditorInput />}
                                                name={`${fieldName}[${index}].content`}
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <Button block style={{ height: "100%" }} onClick={() => { remove(index) }} icon={<DeleteOutlined />}></Button>
                                        </Col>
                                    </Row>
                                </Input.Group>

                                <FieldErrorMessage errors={errors} name={`${fieldName}[${index}].countryCode`} />
                                <FieldErrorMessage errors={errors} name={`${fieldName}[${index}].number`} />
                            </Col>
                        </Row>


                    );
                })}
                <Button block onClick={() => { append({}) }} icon={<PlusOutlined />}></Button>
                <FieldErrorMessage errors={errors} name={fieldName} />
            </Space>

        </Card >
    )

};

export default TabsCreatorField;