import { InputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import React from 'react';


const PriceInput: React.FC<InputNumberProps> = ({ ...props }) => {
    return (
        <InputNumber {...props} min={0} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')} />
    )
}

export default PriceInput;