import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Descriptions, List, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import ItemVariationEditModal from "./ItemVariationEditModal";

const ItemVariationEditor: React.FC<{ itemId?: string }> = ({ itemId }) => {
  const [item, setItem]: [any, any] = useState([]);
  const [loading, setLoading] = useState(false);

  const refreshData = () => {
    setLoading(true);
    if (itemId)
      Api.items.singleWithVariations(parseInt(itemId)).then((res) => {
        setItem(res.data.data);
        setLoading(false);
      });
  };
  const onDeleteVariation = (variationId: number) => {
    Api.itemVariation.delete(variationId).finally(refreshData);
  };
  useEffect(() => {
    refreshData();
  }, []);
  return (
    <div className="white-container">
      <List
        footer={<ItemVariationEditModal itemId={item._id} />}
        itemLayout="horizontal"
        loading={loading}
        dataSource={item.variations}
        rowKey="_id"
        renderItem={(item: any, index) => (
          <List.Item>
            <Descriptions column={6} title={`Variation ${index + 1}`}>
              <Descriptions.Item label="Options ">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {item.options.map((opt: any) => (
                    <span key={opt.option._id} style={{ marginRight: 5 }}>
                      {opt.value}
                    </span>
                  ))}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Sku">{item.sku}</Descriptions.Item>
              <Descriptions.Item label="Price">
                {item.pricing ? item.pricing.retail : "none"}
              </Descriptions.Item>
              <Descriptions.Item label="Compare At Price">
                {item.pricing ? item.pricing.compareAtPrice : "none"}
              </Descriptions.Item>
              <Descriptions.Item label="Percentage Off">
                {item.pricing ? item.pricing.percentageOff : "none"}
              </Descriptions.Item>
              <Descriptions.Item label="Quantity">{item.quantity}</Descriptions.Item>
              <Descriptions.Item label="Weight">{item.shipping?.weight}</Descriptions.Item>
              <Descriptions.Item label="Dimensions">{item.dimensions}</Descriptions.Item>
              {/* <Descriptions.Item label="Reserved">
                            0
                        </Descriptions.Item> */}
              <Descriptions.Item label="Sold">-</Descriptions.Item>
            </Descriptions>
            <div
              style={{
                display: "flex",
                justifyItems: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Popconfirm
                title="Are you sure you want to delete this variation"
                onConfirm={() => {
                  onDeleteVariation(item._id);
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined />
              </Popconfirm>
              <div style={{ marginLeft: 10 }}>
                <ItemVariationEditModal onChange={refreshData} variationId={parseInt(item._id)}>
                  <EditOutlined style={{ cursor: "pointer" }} />
                </ItemVariationEditModal>
              </div>
            </div>
          </List.Item>
        )}
      />
      <ItemVariationEditModal onChange={refreshData} itemId={item._id}>
        <PlusOutlined style={{ cursor: "pointer" }} />
      </ItemVariationEditModal>
    </div>
  );
};

export default ItemVariationEditor;
