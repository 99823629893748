import { Button, Col, DatePicker, Input, message, Result, Row, Select, Space } from "antd";
import moment from "moment";
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";
import TextEditorInput from "./TextEditorInput";


const BlogForm = () => {
    let { id }:any = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/blogs')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.blogs.single, id, setValue: reset,
        //  imageFields
    })

    const type = useWatch({ control, name: "type", defaultValue: null });
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.blogs.create,
            updateApiCall: Api.blogs.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/blogs">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"title"}>Title</label>
                        <Controller
                            control={control}
                            as={<Input id={"title"} />}
                            rules={{ required: true }}
                            name="title"
                        />
                        <FieldErrorMessage errors={errors} name="title" />
                    </Col>

                </Row>

              
     

                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"smallDescription"}>Small Description</label>
                        <Controller
                            control={control}
                            as={<Input id={"smallDescription"} />}
                            rules={{ required: true }}
                            name="smallDescription"
                        />
                        <FieldErrorMessage errors={errors} name="smallDescription" />
                    </Col>

                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"text"}>Text</label>
                        <Controller
                            control={control}
                            as={TextEditorInput}
                            rules={{ required: false }}
                            name="text"
                        />
                        <FieldErrorMessage errors={errors} name="text" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label htmlFor={"thumbnailImage"}>Thumbnail Image</label>
                        <Controller
                            control={control}
                            as={<FileUploadV2 singleValue limit={1} />}
                            name="thumbnailImage"
                        />
                        <FieldErrorMessage errors={errors} name="thumbnailImage" />
                    </Col>
                </Row>

                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={24}>
                        <label htmlFor={"publishDate"}>Publish Date</label>
                        <Controller
                            control={control}
                            render={({ onChange, value }) =>
                                <DatePicker style={{ width: "100%" }}
                                    showTime
                                    value={value ? moment(value) : null}
                                    onChange={(v) => onChange(v?.toDate())} />
                            }
                            name="publishDate"
                        />
                        <FieldErrorMessage errors={errors} name="publishDate" />
                    </Col>
                </Row>

            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default BlogForm;