import React, { Suspense } from 'react';
import './App.css';
import { AppLayout } from "./components/Layout";
import { useTrackedState, } from 'reactive-react-redux';
import { State } from "./store";
import { Login } from "./components/Login";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "./routes";
import { PageNotFound } from "./components/PageNotFound";
import { Skeleton } from 'antd';

function App() {

    return (
        <Router>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <AppLayout>
                    <Suspense fallback={<Skeleton />}>
                        {/* <Switch> */}
                        <Switch>

                            {
                                Routes.map((route: any) => (



                                    <PrivateRoute exact={route.isHomePage} key={route.path} path={route.path}>
                                        {
                                            route.routes ?
                                                <Switch>
                                                    <PrivateRoute exact key={route.path} path={route.path}>
                                                        {React.createElement(route.component)}
                                                    </PrivateRoute>
                                                    {
                                                        route.routes.map((r: any) => (
                                                            <PrivateRoute key={r.path} path={r.path}>
                                                                {React.createElement(r.component)}
                                                            </PrivateRoute>
                                                        ))
                                                    }
                                                    <Route>
                                                        <PageNotFound />
                                                    </Route>
                                                </Switch> :
                                                React.createElement(route.component)

                                        }

                                    </PrivateRoute>

                                ))

                            }
                            <Route>
                                <PageNotFound />
                            </Route>
                        </Switch>



                        {/* </Switch> */}
                    </Suspense>

                </AppLayout>


            </Switch>
        </Router>
    )

}



function PrivateRoute({ children, ...rest }: { children: React.ReactNode, [k: string]: any }) {
    const state = useTrackedState<State>();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                state.isLoggedIn ? (
                    children
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: location }
                            }}
                        />
                    )
            }
        />
    );
}

export default App;
