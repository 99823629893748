import { AutoComplete, Select, Spin } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import React, { useEffect, useState } from 'react';
import { Api } from '../../api';
import { useItemOptionsList } from '../../api/useItemOptionsList';

type ItemOptionPickerProps = {
    value?: number;
    onChange?: any;
    selectedOptions: string[];
}
const ItemOptionPicker: React.FC<ItemOptionPickerProps> = ({ value, onChange, selectedOptions }) => {
    const [data, isLoading] = useItemOptionsList();

   
    let [options, setOptions]: any = useState();
    useEffect(() => {
        setOptions(data.map(i => ({ label: i.label, value: i._id })));
    }, [data]);

    if (isLoading)
        return <Spin />

    return (
        <Select style={{ width: '100%' }} value={value} onChange={onChange} options={options.filter((opt: any) => {
            return !selectedOptions.find(i => i === opt.value)
        })}>
        </Select>
    )
};

export default ItemOptionPicker;