import { Descriptions, PageHeader, Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Routes } from '../routes';
import { Breadcrumbs } from './Breadcrumbs';


export const ModuleHeader = () => {
    const breadcrumbs: any = useBreadcrumbs(Routes);
    const current = breadcrumbs[breadcrumbs.length - 1];
    const [data, setData]: [any, any] = useState([]);
    const history = useHistory();
    useEffect(() => {
        if (current.dataRoute)
            current.dataRoute().then((res: any) => {
                setData(res.data);
            })
        else
            setData([]);
    }, [current.key]);
    return (
        <div style={{ padding: 7, marginTop: 16, background: 'white' }}>
            <Breadcrumbs />
            {
                !current.hidePageHeader &&
                <PageHeader

                    className="site-page-header"

                    onBack={() => history.goBack()}
                    title={current.title}
                    extra={current.buttons || []}
                >
                    <Descriptions size="small" column={3}>
                        {
                            data.map((i: Record<string, string>) => (
                                <Descriptions.Item key={i.label} label={i.label}>{i.value}</Descriptions.Item>
                            ))
                        }

                    </Descriptions>

                </PageHeader>
            }
        </div>
    )
};