import React, { useEffect, useCallback, useRef, useState } from "react";
import { Row, Col, Input, Button, Checkbox, Space, Select } from "antd";
import { Controller, useForm } from "react-hook-form";
import { FieldErrorMessage } from "../FieldErrorMessage";
import { OrderStatus } from "../../types/order";

export type ItemRequestTableFilterProp = {
    value: any;
    onChange: (value: any) => void;
    filterDefinitions?: any;
    deletedFilter?: boolean;
};
const default_definitions = [
    {
        key: "_id",
        type: "number",
        label: "ID",
        span: 3,
    },
    {
        key: "status",
        type: "string",

    }
];
const OrdersTableFilter = ({
    value,
    onChange,
    filterDefinitions,
    deletedFilter = true,
}: ItemRequestTableFilterProp) => {
    const { handleSubmit, errors, control, reset, watch } = useForm({ defaultValues: value });
    const [definitions, setDefinitions] = useState(filterDefinitions || default_definitions);

    useEffect(() => {
        if (deletedFilter)
            setDefinitions([
                ...definitions,
                {
                    key: "showDeleted",
                    type: "showDeleted",
                    excludeFromGenerator: true,
                },
            ]);
    }, [deletedFilter]);
    const clearFilter = () => {
        //@ts-ignore
        reset({ showDeleted: false });
        onChange({
            filter: {},
            definitions,
        });
    };

    const handleChange = useCallback(
        (v: any) => {
            onChange({
                filter: v,
                definitions,
            });
        },
        [onChange]
    );
    // const call = useCallback(() => {
    //     reset(value);
    // }, [value, reset]);
    // useEffect(() => {
    //     call();
    // }, [value, call])
    const watchAllFields = watch("showDeleted");
    const watchStatus = watch("status");
    useEffect(() => {
        if (watchAllFields !== undefined && watchStatus !== undefined) {
            handleSubmit(handleChange)();
        }
    }, [watchAllFields,]);
    useEffect(() => {
        console.log(watchStatus);
        handleSubmit(handleChange)(); 
    },[watchStatus])
    return (
        <div className="white-container" style={{ marginBottom: 12 }}>
            <form onSubmit={handleSubmit(handleChange)}>
                <Space size="large" style={{ width: "100%" }} direction="vertical">
                    <Row style={{ width: "100%" }} gutter={12}>
                        <Col span={3}>
                            <div className="">
                                <label className="mr-10" htmlFor={"_id"}>
                                    ID
                                </label>
                                <Controller control={control} as={<Input id={"_id"} />} name={"_id"} />
                            </div>
                            <FieldErrorMessage
                                style={{ textAlign: "center" }}
                                errors={errors}
                                name={"_id"}
                                formatName={"ID"}
                            />
                        </Col>
                        <Col span={3}>
                            <div className="">
                                <label className="mr-10" htmlFor={"_id"}>
                                    Status
                                </label>
                                <Controller control={control} as={<Select allowClear={true} style={{ width: "100%" }} options={[
                                    {
                                        label: "Awaiting Shipping",
                                        value: OrderStatus.awaitingShipping,
                                    },
                                    {
                                        label: "Shipped",
                                        value: OrderStatus.shipped,
                                    },
                                    {
                                        label: "Canceled",
                                        value: OrderStatus.canceled,
                                    },
                                    {
                                        label: "Awaiting Payment",
                                        value: OrderStatus.awaitingPayment,
                                    },
                                    {
                                        label: "Completed",
                                        value: OrderStatus.completed,
                                    }
                                ]} />} name={"status"} />
                            </div>
                            <FieldErrorMessage
                                style={{ textAlign: "center" }}
                                errors={errors}
                                name={"status"}
                                formatName={"Status"}
                            />
                        </Col>
                    </Row>
                    <Row justify="end">
                        <Space size="small">
                            <div className="">
                                <label className="mr-10" htmlFor={"showDeleted"}>
                                    Show Deleted
                </label>
                                <Controller
                                    onChange={([e]: any) => e.target.checked}
                                    control={control}
                                    valueName="checked"
                                    as={<Checkbox />}
                                    name="showDeleted"
                                />
                            </div>
                            <Button htmlType="submit" size="small" type="dashed">
                                Submit
              </Button>
                            <Button size="small" onClick={clearFilter} type="danger">
                                Reset
              </Button>
                        </Space>
                    </Row>
                </Space>
            </form>
        </div>
    );
};

export default OrdersTableFilter;
