import { BellOutlined, DeleteOutlined, LogoutOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Empty, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../api";

type NotificationsItem = {
  type: string;
  order: any;
  to: number;
  _id: number;
};
const mapNotifications: any = (notifications: NotificationsItem[]) => {
  return notifications.map((notification) => {
    switch (notification.type) {
      case "NEW_ORDER":
        if(!notification.order) return null;
        return (
          <Menu.Item>
            <Link to={`/orders/update/${notification.order._id}`}>
              New Order Received #{notification.order.number}
            </Link>
          </Menu.Item>
        );
      default:
        return null;
    }
  });
};

const NotificationsHeader = () => {
  const [notifications, setNotifications]: any = useState([]);

  useEffect(() => {
    Api.profile.myNotifications().then((res) => {
      setNotifications(res.data.notifications);
    });
  }, []);
  const onClearNotifications = () => {
    Api.profile.clearMyNotifications().then((res) => {
      setNotifications(res.data.notifications);
    });
  };
  return (
    <div>
      <Dropdown
        placement="bottomRight"
        trigger={["click"]}
        overlay={
          notifications.length === 0 ? (
            <Menu theme="dark">
              <Menu.Item>
                <Empty description="Nothing  here, all clear!" />
              </Menu.Item>
            </Menu>
          ) : (
            <Menu theme="dark">
              {mapNotifications(notifications)}
              <Menu.Item onClick={onClearNotifications}>
                <DeleteOutlined />
                Clear Notifications
              </Menu.Item>
            </Menu>
          )
        }
      >
        <div>
          <Badge dot count={notifications.length}>
            <BellOutlined style={{ fontSize: 20 }} />
          </Badge>
        </div>
      </Dropdown>
    </div>
  );
};

export default NotificationsHeader;
