import React, { ReactChildren, useState, useEffect } from 'react';
import { Select } from 'antd';
import { Api } from '../api';

export const TagsInput = ({ children, tags = [], ...args }: any) => {
    const [loading, setLoading] = useState(false);
    const [tagsList, setTags] = useState(tags);

    useEffect(() => {
        setTags(tags);
    }, [tags])

    const handleSelect = (v: any) => {
        let found = tagsList.find((i: any) => i.name === v);
        if (!found)
            Api.tags.create({ name: v }).then(res => {
                setTags([...tagsList, res.data.data]);
            })
    }

    return (
        <Select {...args}
            loading={loading}
            onSelect={handleSelect}
            mode="tags"
        >
            {
                tagsList.map((i: any) => (
                    <Select.Option value={i.name} key={i.name}>{i.name}</Select.Option>
                ))
            }
        </Select>
    )
}