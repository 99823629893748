import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';




const columns = [
    {
        title: 'Code',
        dataIndex: 'code',
        key: "code",
        sorter: true,
        width: '20%',
        render: (text: string, doc: any) => {
            return (<Link to={`/promoCodes/update/${doc._id}`}>{text}</Link>)
        }                                                       
    },
    {
        title: 'Times Used',
        dataIndex: 'uses',
        key: "uses",
        sorter: true,
        width: '20%',
    },
    {
        title: 'Allowed Uses',
        dataIndex: 'numberOfUses',
        key: "numberOfUses",
        sorter: true,
        width: '20%',
    },
];

const PromoCodeTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={"promoCodesTable"}
                deleteApiCall={Api.promoCodes.delete}
                allowDelete={true}
                restoreApiCall={Api.promoCodes.restore}
                actions={(record: any) => (
                    <Link to={`/promoCodes/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
                apiCall={Api.promoCodes.table} columns={columns} />
        </Space>
    )
};

export default PromoCodeTable;