import { Select } from 'antd';
import React, { useEffect, useState } from 'react';


const SelectWithApiData: React.FC<{ value?: any; onChange?: any; apiCall: any; dataMappingFunction: any }> = ({ value, onChange, apiCall, dataMappingFunction }) => {
    const [state, setState] = useState({ data: [], loading: false });
    useEffect(() => {
        if (apiCall) {
            setState({ ...state, loading: true });
            apiCall().then((res: any) => {
                setState({ data: res.data, loading: false });
            })
        }
    }, [])
    return (
        <Select style={{ width: "100%" }} value={value} options={dataMappingFunction(state.data || [])} onChange={onChange} />
    )
};

export default SelectWithApiData;